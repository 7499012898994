import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingIndicatorProgress } from '@ohif/ui';
import { useReactToPrint } from 'react-to-print';
import { useAuthenticationContext, useXylexaAppContext } from './../context';
import { useGetAiReport, useGetStudyReport } from '../api-client';
import { Appbar, CreateReportModal } from './components';
import { getAiReportBody, getFooter, getHeader } from './helpers';
import { createPortal } from 'react-dom';

export const ViewReport = () => {
  const pdfRef = useRef();
  const navigate = useNavigate();

  /**
   * Creating isGenericReportExists state to handle edit report logic. As we
   * do not allow users to edit AI generated report.
   */
  const [isGenericReportExists, setIsGenericReportExists] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [isAIReportPreview, setIsAIReportPreview] = useState<boolean>(false);

  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  const {
    setSelectedModality,
    setSelectedTemplate,
    selectedStudy,
    setIsNewReport,
    getStudyReportKey,
    isInsideViewer,
  } = useXylexaAppContext();

  const { userInfo } = useAuthenticationContext();

  const {
    data: studyReportData,
    isFetching: isStudyReportFetching,
    status: getStudyReportStatus,
  } = useGetStudyReport(selectedStudy?.studyInstanceUid, getStudyReportKey);

  const {
    data: aiReportData,
    isFetching: isAiReportFetching,
    status: getAiReportStatus,
  } = useGetAiReport(selectedStudy?.studyInstanceUid, selectedStudy?.modalities === 'CT');

  //splitting report to extract the header

  const splittedReport = studyReportData?.data.description.split('<hr>');

  const handleBack = e => {
    e.preventDefault();
    setSelectedTemplate(null);
    setSelectedModality('Default');
    setIsNewReport(false);

    if (isInsideViewer) {
      navigate(`/viewer?StudyInstanceUIDs=${selectedStudy?.studyInstanceUid}`);
      return;
    } else {
      navigate('/');
      return;
    }
  };

  /**
   * This will remain true until the component is mounted. Just to conditionally render some options on navBar.
   */

  const ViewReportPreview = true;

  useEffect(() => {
    if (getAiReportStatus === 'success') {
      setIsAIReportPreview(true);
    }
    if (getStudyReportStatus === 'success') {
      setIsGenericReportExists(true);
    }
    if (selectedStudy?.modalities !== 'CT') {
      setIsAIReportPreview(false);
    }
  }, [studyReportData, aiReportData, selectedStudy]);

  return (
    <>
      {isStudyReportFetching || isAiReportFetching ? (
        <LoadingIndicatorProgress className={'bg-secondary-light h-full w-full'} />
      ) : (
        <div className="min-h-screen bg-white">
          <Appbar
            handleOpenDialog={handleBack}
            ViewReportPreview={ViewReportPreview}
            isAIReportPreview={isAIReportPreview}
            setIsAIReportPreview={setIsAIReportPreview}
            isGenericReportExists={isGenericReportExists}
            handlePrint={handlePrint}
          />

          {showModal &&
            createPortal(
              <CreateReportModal setShowModal={setShowModal} />,
              document.body,
              'createReportModal'
            )}

          {selectedStudy?.modalities === 'CT' ? (
            <div className="w-3/2 mx-auto flex h-screen justify-center overflow-auto !text-[black]">
              <div
                style={{
                  marginTop: '100px',
                  width: '60%',
                }}
              >
                {getStudyReportStatus === 'success' && !isAIReportPreview ? (
                  <div
                    ref={pdfRef}
                    style={{
                      padding: '50px',
                    }}
                  >
                    {!isAIReportPreview && (
                      <div
                        dangerouslySetInnerHTML={{ __html: studyReportData?.data?.description }}
                      />
                    )}
                  </div>
                ) : (
                  !isAIReportPreview && (
                    <div className="text-lgs text-center">
                      No report Found! <br />
                      <span
                        className="cursor-pointer"
                        style={{ color: 'blue' }}
                        onClick={() => setShowModal(true)}
                      >
                        Click here
                      </span>{' '}
                      to Create Report.
                    </div>
                  )
                )}

                {getAiReportStatus === 'success' && isAIReportPreview ? (
                  <div
                    ref={pdfRef}
                    style={{
                      padding: '50px',
                    }}
                  >
                    {isAIReportPreview && getStudyReportStatus === 'success' && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${splittedReport[0]}${splittedReport[1]}<br/>${getAiReportBody(aiReportData?.data)}${getFooter(userInfo)}`,
                        }}
                      />
                    )}
                    {isAIReportPreview && getStudyReportStatus === 'error' && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `${getHeader(selectedStudy, '')}<br/>${getAiReportBody(aiReportData?.data)}${getFooter(userInfo)}`,
                        }}
                      />
                    )}
                  </div>
                ) : (
                  isAIReportPreview && (
                    <div className="text-lgs text-center">No AI report Found!</div>
                  )
                )}
              </div>
            </div>
          ) : (
            <div className="w-3/2 mx-auto flex h-screen justify-center overflow-auto !text-[black]">
              <div
                style={{
                  marginTop: '100px',
                  width: '60%',
                }}
              >
                {getStudyReportStatus === 'success' ? (
                  <div
                    ref={pdfRef}
                    style={{
                      padding: '50px',
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: studyReportData?.data?.description }} />
                  </div>
                ) : (
                  <div className="text-lgs text-center">
                    No report Found! <br />
                    <span
                      className="cursor-pointer"
                      style={{ color: 'blue' }}
                      onClick={() => setShowModal(true)}
                    >
                      Click here
                    </span>{' '}
                    to Create Report.
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ViewReport;
