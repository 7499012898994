import { UserInfo } from './../../api-client';
const getFooter = (userInfo: UserInfo) => {
  return `<table style="border-collapse: collapse; width: 100%">
  <table style="border-collapse: collapse; width: 99.8797%">
    <tbody>
      <tr>
        <td style="width: 21.0843%; vertical-align: top">
          <span style="font-family: Arial, Helvetica, sans-serif"
            ><strong style="font-size: 16px">E-Signature:</strong></span
          >
        </td>
        <td style="width: 78.9157%; font-family: Arial, Helvetica, sans-serif, font-size: 13px">
         <i><input style="font-style: italic; background-color:rgba(0, 0, 0, 0); color:black; border: none; outline:none;" type="text" value=${userInfo.username} disabled></i>
        </td>
      </tr>
    </tbody>
  </table>`;
};

export default getFooter;
